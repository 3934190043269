<div class="container main col-12 pt-5 pb-5 ">
    <div class="container">
        <h5 class="text-center p-3">PREDIAL URBANO</h5>
        <div id="consulta">
            <div id="linea"></div>
            <h1 class="text-center p-3">CONSULTA TU CLAVE CATASTRAL</h1>
            <div id="linea"></div>
        </div>
        <p class="text-center p-3">"Ahorra tiempo, evita desplazamientos"</p>

        <div class="row pt-3">
            <div class="form-group col-md-2 col-4">
                <input id="mun" class="form-control pl-lg-4" type="tel" [readonly]="true"
                    (keypress)="numberOnly($event)" maxlength="3" [(ngModel)]="cve_catast.mun">
            </div>
            <div class="form-group col-md-2 col-4">
                <input id="pob" class="form-control pl-lg-4" type="tel" placeholder="POB"
                    (keypress)="numberOnly($event)" maxlength="3" (focus)="$event.target.select()" [(ngModel)]="cve_catast.pob">
            </div>
            <div class="form-group col-md-2 col-4">
                <input id="cua" class="form-control pl-lg-4" type="tel" placeholder="CUA"
                    (keypress)="numberOnly($event)" maxlength="3" (focus)="$event.target.select()" [(ngModel)]="cve_catast.cua">
            </div>
            <div class="form-group col-md-2 col-4">
                <input id="man" class="form-control pl-lg-4" type="tel" placeholder="MAN"
                    (keypress)="numberOnly($event)" maxlength="3" (focus)="$event.target.select()" [(ngModel)]="cve_catast.man">
            </div>
            <div class="form-group col-md-2 col-4">
                <input id="pre" class="form-control pl-lg-4" type="tel" placeholder="PRE"
                    (keypress)="numberOnly($event)" maxlength="3" (focus)="$event.target.select()" [(ngModel)]="cve_catast.pre">
            </div>
            <div class="form-group col-md-2 col-4">
                <input id="uni" class="form-control pl-lg-4" type="tel" placeholder="UNI"
                    (keypress)="numberOnly($event)" maxlength="3" (focus)="$event.target.select()" [(ngModel)]="cve_catast.uni">
            </div>
        </div>
        <div class="text-center pt-3">
            <button class="btn btn-danger" id="btnconsultar" (click)="ConsultarAdeudo()">Consultar <i class="fas fa-search pl-1"></i></button>
        </div>

    </div>
</div>
<div class="container" id="informacion">
    <div class="row">
        <div class="col-md-4 mb-3" data-toggle="modal" data-target="#ayuda_pago">
            <div class="card p-5">
                <img class="mx-auto" src="https://pagoselota.com.mx/img/iconoprocesopago.png" alt="" title="" 
                    style="width: 120px; background-color: white; border-radius: 8%;">
                <p class="pt-3 text-center">¿Cómo realizo un pago en línea?</p>
            </div>
        </div>
        <div class="col-md-4 mb-3" data-toggle="modal" data-target="#clave_catastral">
            <div class="card p-5">
                <img class="mx-auto" src="https://pagoselota.com.mx/img/iconoclavecatastral.png" alt="" title=""
                    style="width: 120px; background-color: white; border-radius: 8%;">
                <p class="pt-3 text-center">Ubica tu clave catastral</p>
            </div>
        </div>
        <div class="col-md-4 mb-3">
            <div class="card p-5" data-toggle="modal" data-target="#consultar_adeudo">
                <img class="mx-auto" src="https://pagoselota.com.mx/img/iconoclavecatastral.png" alt="" title=""
                    style="width: 120px; background-color: white; border-radius: 8%;">
                <p class="pt-3 text-center">Consulta tu adeudo</p>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="dialog">
    <div class="modal" id="ayuda_pago" tabindex="-1" role="dialog">
        <div class="modal-lg modal-dialog modal-dialog-centered">
            <div class="modal-content" role="document">
                <!-- <img *ngIf="es_movil" src="../../../../../assets/escuinapa/GUIA_PAGO_VERTICAL.png" alt="" style="height: 80vh; width: 80vw; "> -->
                <img *ngIf="!es_movil" src="{{img_ayuda_1}}" alt="" style="height: 80vh; ">
                <div >
                   
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Clave Catastral -->
<div class="dialog">
    <div class="modal" id="clave_catastral" tabindex="-1" role="dialog">
        <div class="modal-lg modal-dialog modal-dialog-centered">
            <div class="modal-content" role="document">
                <!-- <img *ngIf="es_movil" src="../../../../../assets/escuinapa/CLAVE_CATASTRAL_VERTICAL.png" alt="" style="height: 80vh; width: 80vw; "> -->
                <img *ngIf="!es_movil" src="{{img_ayuda_2}}" alt="" style="height: 80vh; ">
                <div >
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Consultar Adeudo -->
<div class="dialog">
    <div class="modal" id="consultar_adeudo" tabindex="-1" role="dialog">
        <div class="modal-lg modal-dialog modal-dialog-centered">
            <div class="modal-content" role="document">
                <!-- <img *ngIf="es_movil" src="../../../../../assets/escuinapa/CONSULTAR_ADEUDO_VERTICAL.png" alt="" style="height: 80vh; width: 80vw; "> -->
                <img *ngIf="!es_movil" src="{{img_ayuda_3}}" alt="" style="height: 80vh;  ">
                <div >
                   
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">Modal Title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  

<!-- Modal -->
<div class="dialog">
    <div class="modal" id="clave_catastral" tabindex="-1" role="dialog">
        <div class="modal-lg modal-dialog">
            <div class="modal-content" role="document">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="height: 800px; width: 500px;">
                    <img src="../../../../../assets/elota/ubicareciboelota.jpg" alt="" style="height: 750px; width: 470px;">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="dialog">
    <div class="modal" id="consulta_adeudo" tabindex="-1" role="dialog">
        <div class="modal-lg modal-dialog">
            <div class="modal-content" role="document">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="height: 800px; width: 500px;">
                    <img src="../../../../../assets/elota/consultatuadeudo.jpg" alt="" style="height: 750px; width: 470px;">
                </div>
            </div>
        </div>
    </div>
</div>