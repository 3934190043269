export const environment = {
    banorte: false,
    production: true,
    ente: "H. Ayuntamiento de El Fuerte",
    administracion: "2024 - 2027",
    url_escudo: "../assets/elfuerte/ESCUDO.png",
    url_administracion: "../assets/elfuerte/LOGOTIPO EL FUERTE 2024 - 2027.png",
    url_footer: "../assets/elfuerte/FOOTER.png",
    direcciones: [
        "calle 5 de mayo interior del palacio municipal colonia centro",
        "C.P. 81820",
        "El Fuerte, Sinaloa"
    ],
    telefonos: [
        "698 893 03 90 extensión (1129)"
    ],
    emails: [
        "ingresos2021@elfuerte.gob.mx"
    ],
    url_aviso: "https://www.elfuerte.gob.mx/",
    url_webapi: "https://servidorprogramacion.ddns.net/PAGOS_SADMUN_EL_FUERTE/api/",
    url_sitio: "https://pagosescuinapa.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/Ingresos/GetReciboPredial',
    cve_municipio: "002",
    municipio: "EL FUERTE",
    firebase: {
        apiKey: "AIzaSyDyJJqvXaXFQ9UjHRej2WrdRAlMeDvZyyI",
        authDomain: "pagoselfuerte.firebaseapp.com",
        projectId: "pagoselfuerte",
        storageBucket: "pagoselfuerte.appspot.com",
        messagingSenderId: "51585734132",
        appId: "1:51585734132:web:4290579064793d4fa115a1",
        measurementId: "G-TNTREL4G95"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de El Fuerte para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "2083",
    llave: "y-7p53s-wuVeZY1u-3II",
    img_ayuda_1: '../assets/elfuerte/img_ayuda_1.jpg',
    img_ayuda_2: '../assets/elfuerte/img_ayuda_2.jpg',
    img_ayuda_3: '../assets/elfuerte/img_ayuda_3.jpg'
};
